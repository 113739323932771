//-------------------------------------------------------------------//
// Module: Login                                                     //
// Tasks: 1) Handles Login Page Interaction and API calls            //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import CustomLoader from "../custom_elements/CustomLoader";
import SuccessMessage from "../custom_elements/SuccessMessage";
import { focusFirstElement, customPrint,isChrome, getSessionStoredJsonItem,getUserId, refreshPage, getUserRole, simplifyModulePermissionData, getHeaders } from "../helper";
import { baseURL, PRINT_CATEGORIES } from "../../configuration/constants";
import Auth from "../../authentication/Auth";
import { toast } from "react-toastify";

import React from "react";
import axios from "axios";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Popup,
  Message,
  Input,
  GridColumn,
  Modal,
  Label
} from "semantic-ui-react";
import ForgotPasswordModal from "./ForgotPasswordModal";

/**
 *
 */

class Login extends React.Component {
  is_mounted = true;
  getInitState = () => {
    return {
      is_login_successful: false,
      is_login_failed: false,
      is_waiting_for_response: false,
      is_message_visible: true,
      response_message: "",
      is_page_loading: true,
      Modulepermission: "",
      username: "",
      password: "",
      new_password: "",
      role_id: "",
      resetPassword:false,
      resetConfirmPassword:"",
      user_id:"",
      Error_Msg:"",error_password:""
    };
  };

  state = this.getInitState();

  componentWillUnmount() {
    this.is_mounted = false;
  }

  componentDidMount() {
    this.is_mounted = true;
    localStorage.removeItem('login_success');
    document.title = "Login | Probiotic Formulation";
    focusFirstElement();

    this.setState({
      is_page_loading: false
    });
  }

  onInputChange = (e, { name, value }) => {

    if (value.codePointAt(0) == 32) {
      return false;
    }
    
    if(value =="" && name == "new_password"){
      this.setState({
        [name]:"",
        error_password: "Password is required"
      });
      return;
    }


    this.setState({
      [name]: value,
      error_password:""
    });
  };
  getUserPermission = () => {

    let Role = getUserRole();
    let userId = getUserId();
    

    axios
      .get(baseURL + "/users/PermissionBasedonRoles/" + userId, getHeaders())
      .then(response => {
        let permissionData = null;
        permissionData = simplifyModulePermissionData(response.data);
        permissionData = permissionData.filter(data => {
          if (data.role_name === Role) {
            return data;
          }
        })
        let Modulepermission;
        var userRole = getSessionStoredJsonItem("user");
  
        if (userRole["role_id"] === permissionData[0].roleId) {
   
          if (permissionData.find(element => element.module_name === "All Formulations" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/display_formulations" />;
          }

          if (permissionData.find(element => element.module_name === "All Formulations" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/new_display_formulations" />;
          }

          else if (permissionData.find(element => element.module_name === "Formulation Rationale" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/formulation_rationale" />;
          }
          else if (permissionData.find(element => element.module_name === "Manufacturing Queue" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/display_formulationsOperator" />;
          }
          else if (permissionData.find(element => element.module_name === "Create Inventory" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/create_inventory" />;
          }
          else if (permissionData.find(element => element.module_name === "Inventory" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/inventory" />;
          }
          else if (permissionData.find(element => element.module_name === "Create user" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/create_user" />;
          }
          else if (permissionData.find(element => element.module_name === "Create Group" && (element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/create_group" />;
          }

          else if (permissionData.find(element => element.module_name === "User Management" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/display_users" />;
          }
          else if (permissionData.find(element => element.module_name === "Group Management" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/Group_Management" />;
          }
          // else if (permissionData.find(element => element.module_name == "Prebiotics & Probiotics Management" && (element.view_permission == 1 || element.edit_permission == 1))) {
          //   Modulepermission = <Redirect to="/Prebiotics_and_Probiotics_Management" />;
          // }
          else if (permissionData.find(element => element.module_name === "Create Refill" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/create_refill" />;
          }
          else if (permissionData.find(element => element.module_name === "Flore AI Dashboard" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/create_flore_ai_dashboard" />;
          }
          else if (permissionData.find(element => element.module_name === "Flore AI Success Marker" && (element.view_permission === 1 || element.edit_permission === 1))) {
            Modulepermission = <Redirect to="/create_flore_ai_success_marker" />;
          }
          else {
            Modulepermission = <Redirect to="/NotAuthorised" />;
          }
        
        }

        this.setState({
          Modulepermission,
        });

      })
      .catch(error => {
        console.log("Fetching Display Grid | Error: ", error);
      });

  }
  onLoginClick = event => {
    let res = _.pick(this.state, ["username", "password"]);

    this.setState({ is_waiting_for_response: true });

    customPrint("Login", PRINT_CATEGORIES.REQUEST, baseURL + "/login");

    // API call to check if the user is authorized.
    axios
      .post(baseURL + "/login", res)
      .then(response => {

        customPrint("Login", PRINT_CATEGORIES.RESPONSE, response);
        
        let data = response.data;
        if (data.message === "Unauthorized") {
          this.setState({ is_login_failed: true });
        } else {
          let user = {
            user_id: data.user_id,
            access_token: data.access_token,
            token_type: data.token_type,
            role_name: data.role_name.toLowerCase(),
            full_name: data.full_name,
            expires_at: data.expires_at,
            role_id: data.role_id, 
            pin:data.pin,
            hide_entry: data.hide_entry
          };

          Auth.login(user);
          // check if the browser is Google Chrome.
          isChrome();
          this.setState({ is_login_successful: true });
          if (this.state.is_login_successful == true) {
            localStorage.setItem('login_success', "1");
            setTimeout(() => {
              refreshPage();
            },3000)
          }
          else {
            localStorage.setItem('login_success', "0");
          }
        }
        if (this.is_mounted) {
          this.setState({
            response_message: response.data.message,
            is_waiting_for_response: false,
            is_message_visible: true,
            username: "",
            password: ""
          });

        }
        focusFirstElement();
      })
      .catch((error) => {
        var response_message = "";
        var resetPassword = false;
        var user_id = "";
        if(error.response.data.status == "password_expired"){
          response_message = error.response.data.message;
          resetPassword = !error.response.data.success;  
          user_id = error.response.data.user_id;
        }
        else
        {
          response_message = "Invalid Username or Password!";
        }
        alert(response_message);

        this.setState({
          is_waiting_for_response: false,
          is_login_failed: true,
          response_message,
          resetPassword,
          user_id
        });
      });
  };

  //handles click on cancel button
  onCancelClick = () => this.setState(this.getInitState(),()=>{
    this.setState({is_page_loading:false})
  });  //handles click on cancel button


  onCancelClickReset = () =>{
    this.setState({
      new_password:"",
      resetConfirmPassword:"",
      Error_Msg:"",error_password:""
    });
    };

  //hides the SUCCESS/FAILURE message
  hideMessage = () => {
    this.setState({ is_message_visible: false });
  };

  checkPassword = (value)=>{
    if (value.target.value.codePointAt(0) == 32) {
      return false;
    }
    this.state.resetConfirmPassword = value.target.value;
    var Error_Msg = '';
    if (value.target.value =="") {
      Error_Msg = "Please enter Confirm Password.";
    } else if (this.state.new_password && value.target.value !== this.state.new_password) {
      Error_Msg = "Password and Confirm Password does not match.";
    }
    this.setState({Error_Msg});
  }
  ResetPassword = ()=>{
    this.state.new_password =  this.state.new_password = this.state.new_password.trim()
    this.state.resetConfirmPassword = this.state.resetConfirmPassword.trim();
    
    if(this.state.Error_Msg !== "" || this.state.error_password !== ""){
      return;
    }
    if(this.state.new_password == "" || this.state.resetConfirmPassword == ""){
      toast.error("Please enter valid details");
      return;
    }

      let res = _.pick(this.state, ["user_id","username","email", "password","resetPassword","resetConfirmPassword"]);
      res.old_password = res.password;
      res.new_password = res.resetConfirmPassword;
     axios
      .post(baseURL + "/reset-expired-password", res)
      .then(response => {
        this.setState({is_page_loading:true});
            alert(response.data.message);
            window.location.reload(true);
            customPrint("Reset Password", PRINT_CATEGORIES.RESPONSE, response);
      })
      .catch((error) => {
        this.setState({is_page_loading:false});
        console.log(error.response.data.success);
        if(!error.response.data.success){
          alert(error.response.data.message);
        }
      })
}

  render() {

    if (this.state.is_login_successful) {
      if (localStorage.getItem('login_success') == 1) {
        this.getUserPermission();
     }
      return this.state.Modulepermission;
   }

    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}

        {this.state.is_login_successful && (
          <SuccessMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Login"
            message={this.state.response_message}
          />
        )}
        <br />
        {!this.state.resetPassword && (
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column />
            <Grid.Column>
              <Message>
                <Header as="h2" textAlign="center">
                  Login
                </Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0px" }}>
            <GridColumn />
            <Grid.Column>
              <Segment className="transparent">
                {this.state.is_waiting_for_response && <CustomLoader />}
                <br />
                <br />
                <Form size="large">
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Email Address</label>}
                      content="Enter the Email Address (Email ID)."
                    />
                    <Input
                      name="username"
                      value={this.state.username}
                      onChange={this.onInputChange}
                      icon="info"
                      iconPosition="left"
                      placeholder="Enter Your Email"
                    />
                  </Form.Field>
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Password</label>}
                      content="Enter the Password."
                    />
                    <Input
                      name="password"
                      onChange={this.onInputChange}
                      value={this.state.password}
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder="Enter Your Password"
                    />
                  </Form.Field>
                  <br />
                  <br />
                  <Grid textAlign="center">
                    <Button.Group>
                      <Popup
                        trigger={
                          <Button positive onClick={this.onLoginClick}>
                            Login
                          </Button>
                        }
                        content="Login with registered username and password."
                      />
                      <Button.Or />
                      <Popup
                        trigger={
                          <Button onClick={this.onCancelClick}> Cancel</Button>
                        }
                        content="Cancel Login."
                      />
                    </Button.Group>
                  </Grid>
                  <ForgotPasswordModal
                    onChange={this.onInputChange}
                    username={this.state.username}
                  />
                  <br />
                  <br />
                </Form>
              </Segment>
            </Grid.Column>
            <GridColumn />
          </Grid.Row>
        </Grid>
       )}
       {this.state.resetPassword && (
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column />
            <Grid.Column>
              <Message>
                <Header as="h2" textAlign="center">
                  Reset Password
                </Header>
                  <p style={{textAlign: "center"}}>Your password is more than 90 days old. A password reset is needed.</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0px" }}>
            <GridColumn />
            <Grid.Column>
              <Segment className="transparent">
                <br />
                <br />
                <Form size="large">
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Email Address</label>}
                      content="Enter the Email Address (Email ID)."
                    />
                    <Input
                      name="username"
                      value={this.state.username}
                      onChange={this.onInputChange}
                      icon="info"
                      disabled={true}
                      iconPosition="left"
                      placeholder="Enter Your Email"
                    />
                  </Form.Field>
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Password</label>}
                      content="Enter the Password."
                    />
                    <Input
                      name="new_password"
                      onChange={this.onInputChange}
                      value={this.state.new_password}
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder="Enter Your Password"
                    />
                  </Form.Field>
                  {this.state.error_password !== "" && ( <span style={{color:"red"}}>{this.state.error_password} </span> )}<br/>
                 
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Confirm Password</label>}
                      content="Enter the Confrim Password."
                    />
                    <Input
                      name="resetConfirmPassword"
                      onChange={this.checkPassword}
                      value={this.state.resetConfirmPassword}
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder="Enter Your Password"
                    />
                  </Form.Field>
                  {this.state.Error_Msg && (
                    <span style={{color:"red"}}>{this.state.Error_Msg}<br/></span>
                  )}
                  <br />
                  <br />
                </Form>
                <Grid textAlign="center">
                    <Button.Group>
                      <Popup
                        trigger={
                          <Button positive onClick={this.ResetPassword}>
                            Reset Password

                          </Button>
                        }
                        content="Reset Your password."
                      />
                      <Button.Or />
                      <Popup
                        trigger={
                          <Button onClick={this.onCancelClickReset}> Cancel</Button>
                        }
                        content="Cancel Login."
                      />
                    </Button.Group>
                   
                  </Grid>
                  <Modal
                      style={{ width: "50%" }}
                      trigger={
                        <Grid textAlign="center">
                          <Grid.Row>
                            <Grid.Column>
                              <Label
                                id="hyper-link"
                                className="hyper-link"
                                basic
                                color="blue"
                                onClick={this.onCancelClick}
                              >
                                Login ?
                              </Label>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      }
                    />
                  <br/>
                  <br/>
              </Segment>
            </Grid.Column>
            <GridColumn />
          </Grid.Row>
        </Grid>
       )}



      </React.Fragment>
    );
  }
}

export default Login;
