//-------------------------------------------------------------------//
// Module: Display for Users, Inventory, Formulations                //
// Tasks: 1) According to the module, gets the colDefs               //
//        2) Provide imp info to CustomDisplayGrid                   //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//
import axios from "axios";
import {baseURL} from "../configuration/constants";
  
import CustomDisplayGrid from "./custom_elements/CustomDisplayGrid";
import {
  getUsersColumnDefs,
  getInventoryColumnDefs,
  getFormulationsColumnDefs,
  getFormulationsAdminColumnDefs,
  getFormulationsOperatorColumnDefs,
  getPrebioticsAndProbioticsNamesDefs,
  getModulePermissionDefs,
} from "../components/column_definitions/ColumnDefs";
import Config from "../configuration/config";
import CustomLoader from "./custom_elements/CustomLoader";
import {getUserRole,simplifyModulePermissionData,getHeaders} from "./helper";
import React, { Component } from "react";

class MainDisplayGrid extends Component {
  state = {
    is_page_loading: true,

    users_column_defs: [],
    inventory_column_defs: [],
    formulations_column_defs: [],
    formulations_admin_column_defs: [],
    formulations_operator_column_defs: [],
    formulations_organism_column_defs: [],
    module_permission_column_defs:[],
  };

  componentDidMount() {
    const { module_name } = this.props;

  //  localStorage.removeItem("allFormulation")
  //  localStorage.removeItem("displayFormulation")
  //  localStorage.removeItem("displayInventory")
  //  localStorage.removeItem("userManagement")
  //  localStorage.removeItem("prebioticsManagement")
  //  localStorage.removeItem("groupManagement")
   
    let loginSucess = localStorage.getItem('login_success');
    if (loginSucess == 1) {
      this.getUserPermission();
    }
    // after the component is mount, this fetches all the required module column definitions.
    if (module_name === "users") {
      
      getUsersColumnDefs().then(data => {
        this.setState({ users_column_defs: data });
        this.stopLoading();
      });
    } else if (module_name === "inventory") {
      getInventoryColumnDefs().then(data => {
        this.setState({ inventory_column_defs: data });
        this.stopLoading();
      });
    } else if (module_name === "formulations") {
      getFormulationsColumnDefs().then(data => {
        this.setState({ formulations_column_defs: data });
        this.stopLoading();
      });
    } else if (module_name === "formulationsAdmin") {
      getFormulationsAdminColumnDefs().then(data => {
        this.setState({ formulations_admin_column_defs: data });
        this.stopLoading();
      });
    } else if (module_name === "formulationsOperator") {
      getFormulationsOperatorColumnDefs().then(data => {
        this.setState({ formulations_operator_column_defs: data });
        this.stopLoading();
      });}
      else if (module_name === "formulations_organism") {
        
        getPrebioticsAndProbioticsNamesDefs().then(data => {
          this.setState({ formulations_organism_column_defs: data });
          this.stopLoading();
        });
    }
    else if (module_name === "userPermission") {
        
      getModulePermissionDefs().then(data => {
        this.setState({ module_permission_column_defs: data });
        this.stopLoading();
      });
  }
  }
  getUserPermission =()=>{
 
      let Role = getUserRole();
      let login = localStorage.getItem('login_success');
    
    
      if (login == 1) {
        axios
          .get(baseURL + "/users/PermissionDetails",getHeaders())
          .then(response => {
            let permissionData = null;
    
            permissionData = simplifyModulePermissionData(response.data);
            permissionData = permissionData.filter(data => {
              if (data.role_name === Role) {
                return data;
              }
    
            })
          
            // let disabledPermission
            let Formulationpermission = permissionData.forEach(result => {
          
               if (result.module_name === "All Formulations" && result.edit_permission === 1) {
                
                // disabledPermission=false;
                localStorage.setItem("allFormulation",false)
              }
             
              if (result.module_name === "Manufacturing Queue" && result.edit_permission === 1) {
                
                // disabledPermission=false;
                localStorage.setItem("displayFormulation",false)
              }
              if (result.module_name === "Inventory" && result.edit_permission === 1) {
                
                // disabledPermission=false;
                localStorage.setItem("displayInventory",false)
              }
              if (result.module_name === "User Management" && result.edit_permission === 1) {
                
                // disabledPermission=false;
                localStorage.setItem("userManagement",false)
              }
              if (result.module_name === "Group Management" && result.role_name!=="admin" && result.edit_permission === 1) {
                
                // disabledPermission=false;
                localStorage.setItem("groupManagement",false)
              }
             
              // if (result.module_name == "Prebiotics & Probiotics Management" && result.edit_permission === 1) {
                
              //   disabledPermission=false;
              //   localStorage.setItem("prebioticsManagement",false)
                
              // }
             
            })
            
    
        })
        .catch(error => {
        });
    }
    }
  stopLoading() {
    this.setState({ is_page_loading: false });
  }

  render() {
    let loginSucess = localStorage.getItem('login_success');
    
  if (loginSucess == 1) {
  
    this.getUserPermission();
  }
    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}

        {this.props.module_name === "users" &&
          this.state.users_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.users_column_defs}
              title={Config.users.title.display}
              section_title={Config.users.section_title.display}
              initial_sort={[{ colId: "role", sort: "desc" }]}
              required_fields={["id", "username", "role_id", "full_name","inventory_alert", "hide_entry"]}
              module_name={this.props.module_name}
            />
          )}
        {this.props.module_name === "inventory" &&
          this.state.inventory_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.inventory_column_defs}
              title={Config.inventory.title.display}
              section_title={Config.inventory.section_title.display}
              initial_sort={[{ colId: "created_at", sort: "desc" }]}
              module_name={this.props.module_name}
              required_fields={[
                "id",
                "user_id",
                "receive_date",
                "open_date",
                "expiration_date",
                "organism_barcode",
                "is_available",
                "concentration",
                "manufacture",
                "manufacture_lot",
                "qty",
                "reorder_point",
                "storage_location",
                "shelf",
                "scrap_factor",
                "notes",
                "email_sent",
                "tax_id",
                "real_name",
                "name"
              ]}
            />
          )}
        {this.props.module_name === "formulations" &&
          this.state.formulations_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.formulations_column_defs}
              title={Config.formulations.title.display}
              section_title={Config.formulations.section_title.display}
              initial_sort={[{ colId: "created_at", sort: "desc" }]}
              module_name={this.props.module_name}
              required_fields={[
                "sample_id",
                "formula_id",
                "date_created",
                "date_made",
                "lot",

                "prime_fill_qc_weight",
                "prime_capsule_size",
                "payment_type",
                "prime_powder_remaining",
                "prime_total_capsules_made",
                "prime_flourish_amount_added",
                "prime_filler_amount_added",
                "prime_total_amount_added",
                "prime_filler_inventory_id",
                "flourish_fill_qc_weight",
                "flourish_capsule_size",
                "flourish_powder_remaining",
                "flourish_total_capsules_made",

                "prime_filler_barcode",

                "admin_notes",
                "operator_notes",
                "is_prime",
                "product_type",
                "number_of_months",
                "organism_barcode",
                "amount_added",
                "formulation_factor",
                "scrap_amount",
                "total_cfu",
                "final_concentration",
                "final_amount_added",
                "concentration",
                "total_amount_added",
                "total_final_concentration",
                "total_final_amount_added",

                "created_at",
                "updated_at",
                "deleted_at",
                "is_completed",
                "id",
                "inventory_id",
                "organisms_id",
                "user_id",
                "full_name",
                "user_Id",

              ]}
            />
          )}
        {this.props.module_name === "formulationsAdmin" &&
          this.state.formulations_admin_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.formulations_admin_column_defs}
              title={Config.formulationsAdmin.title.display}
              section_title={Config.formulationsAdmin.section_title.display}
              initial_sort={[{ colId: "created_at", sort: "desc" }]}
              module_name={this.props.module_name}
              required_fields={[
                "sample_id",
                "formula_id",
                "date_created",
                "date_made",
                "admin_notes",
                "number_of_months",
                "organism_barcode",
                "amount_added",
                "formulation_factor",
                "total_cfu",
                "final_concentration",
                "total_final_concentration",
                "user_id",
                "created_at",
                "updated_at",
                "deleted_at",
                "is_completed",
                "id",
                "inventory_id",
                "organisms_id",
                "user_id"
              ]}
            />
          )}
        {this.props.module_name === "formulationsOperator" &&
          this.state.formulations_operator_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.formulations_operator_column_defs}
              title={Config.formulationsOperator.title.display}
              section_title={Config.formulationsOperator.section_title.display}
              initial_sort={[{ colId: "created_at", sort: "desc" }]}
              module_name={this.props.module_name}
              required_fields={[
                "sample_id",
                "formula_id",
                "user_id",
                "prime_fill_qc_weight",
                "prime_capsule_size",
                "prime_powder_remaining",
                "prime_total_capsules_made",
                "prime_flourish_amount_added",
                "prime_filler_amount_added",
                "prime_total_amount_added",
                "prime_filler_inventory_id",
                "flourish_fill_qc_weight",
                "flourish_capsule_size",
                "flourish_powder_remaining",
                "flourish_total_capsules_made",
                "number_of_months",
                "organism_barcode",
                "formulation_factor",
                "final_amount_added",
                "total_amount_added",
                "total_final_amount_added",
                "is_completed",
                "id",
                "inventory_id",
                "organisms_id",
                "user_id",
                "operator_notes",
                
              ]}
            />
          )}
          {this.props.module_name === "formulations_organism" &&
          this.state.formulations_organism_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.formulations_organism_column_defs}
              title={Config.formulations_organism.title.display}
              section_title={Config.formulations_organism.section_title.display}
              initial_sort={[{ colId: "organism_id", sort: "desc" }]}
              required_fields={["organism_id","organism_barcode", "organism_name","tax_id"]}
              module_name={this.props.module_name}
            />
          )}
          {this.props.module_name === "userPermission" &&
          this.state.module_permission_column_defs.length !== 0 && (
            <CustomDisplayGrid
              column_defs={this.state.module_permission_column_defs}
              title={Config.userPermission.title.display}
              section_title={Config.userPermission.section_title.display}
              required_fields={["permissionId","module_name","view_permission", "edit_permission","delete_permission","group_name"]}
              module_name={this.props.module_name}
            />
          )}
      </React.Fragment>
    );
  }
}

export default MainDisplayGrid;
