import React, { Component, createRef } from "react";
import axios from "axios";
import { Message, Header, Segment, Grid, Button, Icon, Checkbox } from "semantic-ui-react";
import { baseURL, portalURL, portalDev } from "../../../configuration/constants";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import "./samples.css";

import { onGridReady, getHeaders, exportAsCSV, Product_Type, getSessionStoredJsonItem } from "../../helper";
import { ToggleButton } from "@material-ui/lab";
import { toast } from "react-toastify";
import CustomLoader from "../../custom_elements/CustomLoader";
// global variables
let api, column_api;

let hide_entry = getSessionStoredJsonItem("user") ? getSessionStoredJsonItem("user").hide_entry : 0;

class Samples extends Component {
  is_mounted = true;
  constructor(props) {
    super(props);
    this.gridRef = createRef()
  }
  state = {
    samples: [],
    sgNumberInQueue: [],
    sgNumberInQueueAfterCreate: [],
    selectedSamples: [],
    selectedRowIds: new Set(), // Use a Set for quick lookup
    detailSample: null,
    selectedRow: null,
    show_hidden: false,
    pre_formulation: true,
    is_page_loading: true,
    columnDefs: [
      {
        headerName: "Sample ID",
        field: "sg_number",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 220,
        checkboxSelection: hide_entry === 1,
        headerCheckboxSelection: hide_entry === 1,
        headerCheckboxSelectionFilteredOnly: hide_entry === 1
      },
      {
        headerName: "Open Report",
        field: "report_id",
        width: 90,
        cellRenderer: (params) => {
          let x = portalDev + params.value
          let target = "_blank"
          return (
            `<a href=${x} target=${target} >
            <i class="fa fa-external-link" aria-hidden="true"></i>
           </a>
            `
          )
        }
      },
      {
        headerName: "Sample Received Date",
        field: "sample_received_date",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 130,
      },
      {
        headerName: "Customer Type",
        field: "customer_type",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        width: 130,
      },
      {
        headerName: "Age",
        field: "profile_dob",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 180,

      },
      {
        headerName: "Product Type",
        field: "product_type",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        width: 250
      },
      {
        headerName: "TimePoint No.",
        field: "sg_number_count",
        filter: "agTextColumnFilter",
      },
    ]
  };
  componentDidMount() {
    this.is_mounted = true;
    // this.getQueueSgNubmers();
    this.getUnhideSamples(false)
  }

  getUnhideSamples = (showToast) => {
    this.setState({ is_page_loading: true })
    axios
      .get(baseURL + "/get_unhide_formula_details", getHeaders())
      .then(response => {
        if (this.is_mounted) {
          this.setState({ sgNumberInQueue: response.data }, () => {
            this.getQueueSgNubmers(showToast)
            // this.getSamples(showToast);
          })
        }
      })

  }

  getSamples = (showToast) => {
    const queryParameters = new URLSearchParams(window.location.search)
    let sample_id = queryParameters.get("sample_id");
    console.log(sample_id);
    if (sample_id !== null && sample_id !== "") {
      sample_id = '/' + sample_id
    } else {
      sample_id = "";
    }

    let url = `${portalURL}/getSGListUpgrade/U3VuX2dlbm9fMTIz${sample_id}`;
    axios
      .get(url)
      .then(res => {
        let data = res.data.data;
        return data;
      })
      .then(samples => {
        let index;
        this.state.sgNumberInQueueAfterCreate.forEach(details => {
          index = samples.findIndex(x => (x.sg_number == details.sample_id && Product_Type[x.product_type] == details.product_type))
          if (index > -1) {
            samples.splice(index, 1);
          }
        })
        if (!this.state.show_hidden) {
          let hidden = this.state.selectedSamples
          this.state.sgNumberInQueue.forEach(details => {
            index = samples.findIndex(x => (x.sg_number == details.sample_id))
            if (index > -1) {
              if (hidden.findIndex(x => (x.sg_number == samples[index].sg_number)) == -1) {
                hidden.push(samples[index])
              }
              samples.splice(index, 1);
            }

          })
          this.setState({ selectedSamples: hidden })
        }
        if (samples.length > 0) {
          this.setState({
            samples: samples,
            pre_formulation: false
          }, () => {
            let hiddenRows = this.state.selectedSamples
            this.gridRef.current.api.forEachNode((node) => {
              this.state.sgNumberInQueue.forEach((details) => {
                if (node.data && node.data.sg_number == details.sample_id) {
                  if (this.state.show_hidden) {
                    node.setSelected(true);
                  } else {
                    node.setSelected(false)
                  }
                  if (hiddenRows.findIndex(x => (x.sg_number == node.data.sg_number)) == -1) {
                    hiddenRows.push(node.data)
                  }
                }
              })
            });
            this.setState({ selectedSamples: hiddenRows })
          });
          if (sample_id !== null && sample_id !== "") {
            this.props.handleSelectedSample(samples[0]);
          }

        }
        this.setState({ is_page_loading: false })
        if (showToast) {
          toast.success("Sample(s) visibility updated successfully.");
        }
      });
  };

  getQueueSgNubmers = (showToast) => {
    axios
      .get(baseURL + "/getQueueRecordsFormulation", getHeaders())
      .then(response => {
        if (this.is_mounted) {
          this.setState({ sgNumberInQueueAfterCreate: response.data }, () => {
            this.getSamples(showToast);
          })
        }
      })

  }

  pastFormulation = () => {
    this.props.openPastFormulas()
  }

  hideSamples = () => {
    this.setState({ is_page_loading: true })
    let selectedRows = this.gridRef.current.api.getSelectedRows();
    let alreadySelected = this.state.selectedSamples
    console.log("alreadySelected", alreadySelected)
    console.log("selectedRows", selectedRows)
    selectedRows = [...alreadySelected, ...selectedRows]
    this.setState({ selectedSamples: selectedRows })
    const selectedSG = selectedRows.map(item => { return item.sg_number })
    if (selectedSG.length > 0) {
      let param = {
        sample_ids: selectedSG
      }
      axios.post(baseURL + "/hide_formulas", param, getHeaders())
        .then(res => {
          let result = res.data;
          if (result.success === true) {
            this.getUnhideSamples(true)
          } else {
            this.setState({ is_page_loading: false })
            toast.error("Sample has not hidden");
          }
        })
    }
  }

  rowStyle = (params) => {

    const { selectedRowIds, detailSample } = this.state;

    if (selectedRowIds.has(params.data.sg_number)) {
      return { background: "#f4d9d6 !important" }; // Selected row style
    } else if (params.data.sg_number === detailSample) {
      return { background: "#b7e4ff" }; // Custom highlight
    }

    return {}; // Default style
  };

  render() {
    return (
      <>
        {this.state.is_page_loading && <CustomLoader />}
        <Message style={{ marginBottom: "0" }}>
          <Header as="h2" textAlign="center">
            Samples
          </Header>
        </Message>
        <Segment
          compact
          raised
          padded
          style={{ display: "block", marginTop: "0" }}
        >
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Button
                  basic
                  onClick={() => exportAsCSV(api, "samples_to_formulate")}
                  icon
                  labelPosition="right"
                >
                  Export as CSV
                  <Icon name="share square" />
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  basic
                  onClick={() => this.pastFormulation()}
                  icon
                  labelPosition="right"
                  disabled={this.state.pre_formulation}
                >
                  Previous Formulation
                  <Icon name="eye" />
                </Button>
                &nbsp;&nbsp;&nbsp;
                {hide_entry === 1 && <Button
                  style={{ fontWeight: "initial" }}
                  secondary
                  onClick={() => this.hideSamples()}
                  icon
                  labelPosition="right"
                >
                  {this.state.show_hidden ? "Hide/Unhide Samples" : "Hide Samples"}
                  <Icon name="hide circle right" />
                </Button>}
                &nbsp;&nbsp;&nbsp;
                <Checkbox
                  toggle
                  value={this.state.show_hidden}
                  label={"Display hidden entries"}
                  checked={this.state.show_hidden}
                  onChange={() => {
                    this.setState(prevState => ({
                      show_hidden: !prevState.show_hidden
                    }), () => { this.getUnhideSamples(false); })
                  }}
                />

              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <div
                  id="myGrid"
                  className="ag-theme-balham"
                  ag-grid="gridOptions"
                  style={{
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <AgGridReact
                    ref={this.gridRef}
                    rowHeight="30"
                    rowSelection="multiple"
                    getRowStyle={this.rowStyle}
                    suppressRowClickSelection={true}
                    onRowClicked={e => {
                      let sample = "";
                      sample = e.data;
                      this.setState({ detailSample: e.data.sg_number }, () => {
                        this.gridRef.current.api.redrawRows();
                      });
                      this.props.handleSelectedSample(sample);
                    }}
                    onRowSelected={e => {
                      const selectedRowIds = new Set(this.state.selectedRowIds);
                      let alreadySelected = this.state.selectedSamples

                      if (e.node.isSelected()) {
                        selectedRowIds.add(e.data.sg_number);
                        alreadySelected.push(e.data)
                      } else {
                        selectedRowIds.delete(e.data.sg_number);
                        alreadySelected = alreadySelected.filter(item => item.sg_number != e.data.sg_number)
                      }

                      this.setState({ selectedRowIds, selectedSamples: alreadySelected }, () => {
                        this.gridRef.current.api.redrawRows();
                      });
                    }}
                    onGridReady={params => {
                      api = params.api;
                      api.showLoadingOverlay();
                      api.sizeColumnsToFit();
                      column_api = params.column_api;
                      onGridReady(
                        params,
                        api,
                        column_api,
                        this.props.initial_sort
                      );
                    }}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationNumberFormatter={params => {
                      return "[" + params.value.toLocaleString() + "]";
                    }}
                    columnDefs={this.state.columnDefs}
                    floatingFilter={true}
                    rowData={this.state.samples}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    );
  }
}

export default Samples;